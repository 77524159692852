import {
    Modal,
    ModalOverlay,
    ModalContent,
    Box,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    Input,
    FormLabel,
    Flex,
    Select,
    Radio,
    RadioGroup,
    Stack,
    Image,
    Center,
  } from "@chakra-ui/react";
  import { useRef, useState } from "react";
  import { authService } from "../../services/auth";
  import { vehicleTypes } from "../../utils/helpers";
  import PhoneInput from "react-phone-input-2";
  import "react-phone-input-2/lib/style.css";
  import { driversService } from "../../services/drivers";
  import { IoMdClose } from "react-icons/io";
import { vehiclesService } from "../../services/vehicles";
  
  export default function RegisterVehicleModal({
    isOpen,
    onClose,
    onRegister,
    onError,
  }) {
    const [data, setData] = useState({});
    const imageInput = useRef();
    const [loading, setLoading] = useState(false);
    const [vehicleImages, setVehicleImages] = useState([]);
    const cloudinaryUrl =
      "https://api.cloudinary.com/v1_1/duldhdjsj/image/upload";
  
    const add = async (e) => {
      try {
        e.preventDefault();
        setLoading(true);
        if (vehicleImages?.length == 0) {
          onError("Upload atleast one image of the vehicle");
          setLoading(false);
          return;
        }
  
        const formDataArray = vehicleImages.map((base64Image) => {
          const formData = new FormData();
          formData.append("file", base64Image);
          formData.append("upload_preset", "wtmnkwiw");
          return formData;
        });
  
        const uploadPromises = formDataArray.map((formData) =>
          fetch(cloudinaryUrl, {
            method: "POST",
            body: formData,
          }).then((response) => response.json())
        );
  
        const results = await Promise.all(uploadPromises);
        const uploadedImages = results.map((result) => result.secure_url);
  
        const { color, vehicleType, seats, plateNumber,model } = data;
        const res = await vehiclesService.registerVehicle({
            color,
            vehicleType,
            seats,
            images: uploadedImages,
            plateNumber,
            model
        });
  
        setLoading(false);
        onRegister();
      } catch (error) {
        setLoading(false);
        console.log(error);
        onError(error?.response?.data?.message || error?.message);
      }
    };
  
    function handleFiles(event) {
      const files = event.target.files;
      const urls = [];
  
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
  
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          urls.push(imageUrl);
          if (files.length == urls.length) {
            setVehicleImages([...vehicleImages, ...urls]);
          }
        };
  
        reader.readAsDataURL(file);
      });
    }
  
    return (
      <Modal isCentered={true} size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Register Vehicle</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box w={"100%"} onSubmit={add} as={"form"}>
              <input
               maxLength={5}
               onChange={handleFiles}
               style={{ display: "none" }}
               type="file"
               ref={imageInput}
               multiple
               accept="image/*"
              />
  
              <Flex columnGap={5} mb={"10px"}>
                <FormControl isRequired>
                  <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                    Vehicle type
                  </FormLabel>
                  <Select
                    fontWeight="medium"
                    fontSize="14px"
                    h={"45px"}
                    mb={"2px"}
                    placeholder="Select vehicle type"
                    onChange={(e) => {
                      setData({ ...data, vehicleType: e.target.value });
                    }}
                  >
                    {vehicleTypes?.map((type) => {
                      return <option value={type?.value}>{type?.label}</option>;
                    })}
                  </Select>
                </FormControl>
  
                <FormControl isRequired>
                  <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                    Vehicle Model
                  </FormLabel>
                  <Input
                    py={"22px"}
                    onChange={(e) => {
                      setData({ ...data, model: e.target.value });
                    }}
                    type={"text"}
                    placeholder="Ex: Nissan, Benz, Corolla, Rava4, etc"
                  />
                </FormControl>
              </Flex>
  
              <FormControl mb={"13px"} isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Vehicle Plate number
                </FormLabel>
                <Input
                  py={"22px"}
                  onChange={(e) => {
                    setData({ ...data, plateNumber: e.target.value });
                  }}
                  type={"text"}
                  placeholder="Enter a plate number"
                />
              </FormControl>
  
              <FormControl isRequired mb={"13px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Upload vehicle image
                </FormLabel>
                <Box
                  py={"30px"}
                  px={"30px"}
                  onClick={() => {
                    imageInput.current.click();
                  }}
                  borderRadius={"12px"}
                  borderStyle={"dashed"}
                  borderWidth={3}
                  borderColor={"lightgray"}
                  bg={"#F8FAFB"}
                  mt={"5px"}
                  textAlign={"center"}
                  cursor={"pointer"}
                >
                  <Button colorScheme={"primary"}>Browse</Button>
                </Box>
                <Box
                  display={"flex"}
                  flexWrap={"wrap"}
                  columnGap={"10px"}
                  rowGap={"10px"}
                  pt={"10px"}
                >
                  {vehicleImages?.map((img, index) => {
                    return (
                      <Center
                        pos={"relative"}
                        width={"150px"}
                        height={"150px"}
                        key={"img" + index}
                        boxShadow={"xs"}
                      >
                        <Image
                          borderRadius={"4px"}
                          objectFit={"contain"}
                          maxHeight={"100%"}
                          maxWidth={"100%"}
                          objectPosition={"top"}
                          src={img}
                        />
                        <button
                          onClick={() => {
                            setVehicleImages(
                              vehicleImages.filter((imgs) => imgs != img)
                            );
                          }}
                          type="button"
                          className="closeBtn"
                        >
                          <IoMdClose size={20} />
                        </button>
                      </Center>
                    );
                  })}
                </Box>
              </FormControl>
  
              <Flex columnGap={5}>
                <FormControl isRequired mb={"10px"}>
                  <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                    Color of the vehicle
                  </FormLabel>
                     <Select
                    fontWeight="medium"
                    fontSize="14px"
                    h={"45px"}
                    mb={"2px"}
                    placeholder="Color of the vehicle"
                    onChange={(e) => {
                      setData({ ...data, color: e.target.value });
                    }}
                  >
                      <option value="Red">Red</option>
    <option value="Blue">Blue</option>
    <option value="Green">Green</option>
    <option value="Black">Black</option>
    <option value="White">White</option>
    <option value="Silver">Silver</option>
    <option value="Yellow">Yellow</option>
    <option value="Other">Other</option>
                  </Select>
                </FormControl>
                <FormControl isRequired mb={"10px"}>
                  <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                    Number Of Seats
                  </FormLabel>
                  <Input
                    py={"22px"}
                    onChange={(e) => {
                      setData({ ...data, seats: parseInt(e.target.value) });
                    }}
                    type={"number"}
                    placeholder="Number of Seats"
                  />
                </FormControl>
              </Flex>
  
              <ModalFooter>
                <Button type={"button"} mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  loadingText={"Saving..."}
                  isLoading={loading}
                  type={"submit"}
                  colorScheme="primary"
                >
                  Save
                </Button>
              </ModalFooter>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
  