import React from "react";
import ReactECharts from "echarts-for-react";

const LineChart = ({ monthlyData }) => {
  const months = monthlyData.map((data) => data.month);
  const trips = monthlyData.map((data) => data.trips);
  const amounts = monthlyData.map((data) => data.amount);

  const option = {
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        return `${params[0].name}<br/>
                Trips: ${params[0].value}<br/>
                Amount: $ ${amounts[params[0].dataIndex]}`;
      },
    },
    xAxis: {
      type: "category",
      data: months,
      axisLine: {
        lineStyle: {
          color: "#3e98d4",
        },
      },
      axisLabel: {
        color: "#333",
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#3e98d4",
        },
      },
      axisLabel: {
        color: "#333",
      },
    },
    series: [
      {
        name: "Total Trips",
        type: "line",
        data: trips,
        itemStyle: {
          color: "#3e98d4",
        },
        lineStyle: {
          color: "#3e98d4",
          width: 2,
        },
        symbolSize: 8,
      },
    ],
    grid: {
      left: "5%",
      right: "5%",
      bottom: "10%",
      containLabel: true,
    },
  };

  return (
    <ReactECharts option={option} style={{ height: "400px", width: "100%" }} />
  );
};

export default LineChart;
