import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { vehiclesService } from "../../services/vehicles";
import { currencyCodes, vehicleTypes } from "../../utils/helpers";

export default function PricingModal({
  isOpen,
  onOpen,
  onClose,
  vehicle,
  onError,
  onSuccess,
}) {
  const [data, setData] = useState({
    pricePerHourDay: vehicle?.pricePerHourDay,
    pricePerHourNight: vehicle?.pricePerHourNight,
    currency: vehicle?.currency,
    dailyPrice: vehicle?.dailyPrice,
    airportOneWayPrice: vehicle?.airportOneWayPrice,
    airportTwoWayPrice: vehicle?.airportTwoWayPrice,
    id: vehicle?.id,
    luggagePrice: vehicle?.luggagePrice,
    protocolPrice: vehicle?.protocolPrice,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData({
      pricePerHourDay: vehicle?.pricePerHourDay,
      pricePerHourNight: vehicle?.pricePerHourNight,
      dailyPrice: vehicle?.dailyPrice,
      airportOneWayPrice: vehicle?.airportOneWayPrice,
      airportTwoWayPrice: vehicle?.airportTwoWayPrice,
      currency: vehicle?.currency,
      id: vehicle?.id,
      luggagePrice: vehicle?.luggagePrice,
      protocolPrice: vehicle?.protocolPrice,
    });
  }, [vehicle]);

  const update = async () => {
    try {
      if (!data.pricePerHourDay) {
        onError("Price is required");
        return;
      }
      setLoading(true);
      const {
        pricePerHourDay,
        dailyPrice,
        airportOneWayPrice,
        airportTwoWayPrice,
        currency,
        id,
        pricePerHourNight,
        luggagePrice,
        protocolPrice,
      } = data;
      const res = await vehiclesService.editPricingData({
        pricePerHourDay: parseInt(pricePerHourDay),
        pricePerHourNight: parseInt(pricePerHourNight),
        currency,
        id,
        airportOneWayPrice,
        airportTwoWayPrice,
        dailyPrice,
        luggagePrice,
        protocolPrice,
      });
      onSuccess(res?.data?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <>
      <Modal isCentered={true} size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {vehicleTypes.find((v) => v.value == vehicle?.vehicleType)?.label}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex columnGap={"20px"} mb={"10px"}>
              <FormControl>
                <FormLabel>Price per hour /Day</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Price/Km"
                    defaultValue={data?.pricePerHourDay}
                    value={data?.pricePerHourDay}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        pricePerHourDay: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel>Price per hour /Night</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Price/Km"
                    defaultValue={data?.pricePerHourNight}
                    value={data?.pricePerHourNight}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        pricePerHourNight: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>
            </Flex>

            <FormControl mb={"10px"}>
              <FormLabel>Daily price</FormLabel>
              <Flex>
                <CurrencyInput
                  className="currency-input-field"
                  id="input-example"
                  name="input-name"
                  placeholder="Daily price"
                  defaultValue={data?.dailyPrice}
                  value={data?.dailyPrice}
                  decimalsLimit={2}
                  maxLength={20}
                  onValueChange={(value, name) => {
                    setData({
                      ...data,
                      dailyPrice: value ? parseInt(value) : "",
                    });
                  }}
                />
                <Select
                  isDisabled={true}
                  borderLeftRadius={0}
                  h={"45px"}
                  bg="neutral.300"
                  w={"150px"}
                  onChange={(e) => {
                    setData({ ...data, currency: e.target.value });
                  }}
                  value={data.currency}
                >
                  <option value={""} disabled>
                    Select currency
                  </option>
                  {currencyCodes?.map((code) => {
                    return (
                      <option
                        selected={data.currency == code ? true : false}
                        value={code}
                      >
                        {code}
                      </option>
                    );
                  })}
                </Select>
              </Flex>
            </FormControl>

            <Flex columnGap={"20px"} mb={"10px"}>
              <FormControl>
                <FormLabel>Airport two way price</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Airport price"
                    defaultValue={data?.airportTwoWayPrice}
                    value={data?.airportTwoWayPrice}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        airportTwoWayPrice: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel>Airport one way price</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Airport price"
                    defaultValue={data?.airportOneWayPrice}
                    value={data?.airportOneWayPrice}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        airportOneWayPrice: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>
            </Flex>

            <Flex columnGap={"20px"} mb={"10px"}>
              <FormControl>
                <FormLabel>Aiport luggage price</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Airport price"
                    defaultValue={data?.luggagePrice}
                    value={data?.luggagePrice}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        luggagePrice: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel>Airport protocol Price</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Airport price"
                    defaultValue={data?.protocolPrice}
                    value={data?.protocolPrice}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        protocolPrice: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>
            </Flex>

            <ModalFooter>
              <Button onClick={onClose} mr={3}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                onClick={update}
                colorScheme="primary"
              >
                Save
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
