import axiosInstance from "../utils/axios";

export const driversService = {
  getDrivers: async (itemsPerPage, currentPage,status,searchKey) => {
    try {
      let url = `/auth?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`;
      if(status){
        url+=`&status=${status}`;
      }
      if(searchKey){
        url+=`&searchKey=${searchKey}`;
      }
      return axiosInstance.get(
        `${url}`
      );
    } catch (error) {
      throw error;
    }
  },
  getDriverById: async (id) => {
    try {
      return axiosInstance.get(`/auth/admin/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getDriversStatistics: async () => {
    try {
      return axiosInstance.get(`/admins/driversStatistics`);
    } catch (error) {
      throw error;
    }
  },
  getDriverMonthlyTrips: async (driverId, year) => {
    try {
      return axiosInstance.get(
        `/statistics/driver/monthlyData/${driverId}?year=${year}`
      );
    } catch (error) {
      throw error;
    }
  },
  getDriverTripsAmount: async (driverId) => {
    try {
      return axiosInstance.get(`/statistics/getDriverStatistics?driverId=${driverId}`);
    } catch (error) {
      throw error;
    }
  },
  matchingDrivers: async (vehicleType) => {
    try {
      return axiosInstance.get(
        `/booking/matchingDrivers?vehicleType=${vehicleType}`
      );
    } catch (error) {
      throw error;
    }
  },
  registerDriver: async (data) => {
    try {
      return axiosInstance.post("/auth/registerDriver", data);
    } catch (error) {
      throw error;
    }
  },
  updateDriver: async (data) => {
    try {
      return axiosInstance.post("/auth/updateDriver", data);
    } catch (error) {
      throw error;
    }
  },
  approveDriver: async (driverId) => {
    try {
      return axiosInstance.put(`/admins/driver/approve/${driverId}`);
    } catch (error) {
      throw error;
    }
  },
  changeUserAccountStatus: async (data) => {
    try {
      return axiosInstance.put(`/auth/changeUserAccountStatus?userId=${data?.userId}&status=${data?.status}`);
    } catch (error) {
      throw error;
    }
  },
};
