import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    Box,
    Flex,
    Text,
    VStack,
    HStack,
    Skeleton,
    Select,
    Textarea,
  } from "@chakra-ui/react";
  import { IoSearch } from "react-icons/io5";
  import React, { useEffect, useState } from "react";
  import { driversService } from "../../services/drivers";
import { vehiclesService } from "../../services/vehicles";
import { rejectionReasons } from "../../utils/helpers";
import { tripsService } from "../../services/trips";
  
  export default function RejectTripModal({ isOpen, onClose,tripId,onSuccess,onError,passengerId }) {
    const [loading, setLoading] = useState(false);
    const [reason,setReason] = useState();
    const [note,setNote] = useState('');

    const rejectTrip = async(e)=>{
        e.preventDefault()
   try{
    if(reason ){
        setLoading(true)
    const res = await tripsService.rejectTrip({
        tripId: tripId,
        reason: reason,
        note: note,
        status: 'REJECTED',
        passengerId: passengerId
      })
    
    onSuccess(res?.data?.message)
    setLoading(false)
    }
   }
   catch(error){
    setLoading(false);
    onError(error?.response?.data?.message || "An error occured, please try again");
   }
    }
  
    return (
      <Modal isCentered={true} scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reject Ride</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={3}>
           <Box onSubmit={rejectTrip} as={'form'}>
            <Box mb={'10px'}>
            <FormControl isRequired mb={"10px"}>
                <FormLabel>Short note</FormLabel>
                <Select
                placeholder="Select the reason"
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    value={reason}
                  >
                    {rejectionReasons()?.map((_reason) => {
                      return (
                        <option
                          selected={reason == _reason?.value ? true : false}
                          value={_reason?.value}
                        >
                          {_reason?.label}
                        </option>
                      );
                    })}
                  </Select>
              </FormControl>
                  </Box>
                  <Box mt={"10px"}>
              <FormControl isRequired mb={"10px"}>
                <FormLabel>Short note</FormLabel>
                <Textarea
                  placeholder="Write short note..."
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </FormControl>
            </Box>
            <ModalFooter>
            <Button type="button" onClick={onClose} colorScheme="gray" mr={3}>
              Close
            </Button>

            <Button loadingText={'Rejecting...'} type="submit" isLoading={loading} colorScheme="danger" mr={3}>
              Reject
            </Button>
          </ModalFooter>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
  