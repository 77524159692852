import { useState } from "react";
import { authService } from "../services/auth";

const useAuth = () => {
  const [user, setUser] = useState({});

  const getCurrentUser = async () => {
    try {
      const res = await authService.getCurrentAdmin();
      setUser(res.data);
    } catch (error) {}
  };

  return {
    user,
    getCurrentUser,
  };
};

export default useAuth;
