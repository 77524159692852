import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    Box,
    Flex,
    Text,
    VStack,
    HStack,
    Skeleton,
    Select,
    Textarea,
  } from "@chakra-ui/react";
  import { IoSearch } from "react-icons/io5";
  import React, { useEffect, useState } from "react";
  import { driversService } from "../../services/drivers";
import { vehiclesService } from "../../services/vehicles";
import { rejectionReasons } from "../../utils/helpers";
import { tripsService } from "../../services/trips";
  
  export default function DisableOrDeleteModal({ isOpen, onClose,userId,onSuccess,onError,title,btnTitle,description,status }) {
    const [loading, setLoading] = useState(false);

    const disableOrDelete = async()=>{
   try{
        setLoading(true);
    const res = await driversService.changeUserAccountStatus({
        userId: userId,
        status: status
      })
    
    onSuccess(res?.data?.message)
    setLoading(false)
   }
   catch(error){
    setLoading(false);
    onError(error?.response?.data?.message || "An error occured, please try again");
   }
    }
  
    return (
      <Modal isCentered={true} scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={3}>
                  <Box>
            <Text>{description}</Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={onClose} colorScheme="gray" mr={3}>
              Close
            </Button>

            <Button loadingText={'Loading...'} onClick={()=>{
                disableOrDelete()
            }} isLoading={loading} colorScheme={status=='ACTIVE'? 'primary' : "danger"} mr={3}>
              {btnTitle}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  