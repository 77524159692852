import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { constantsService } from "../../services/constants";

const ConstantModal = ({ isOpen, onClose, fetchConstants, constant }) => {
  const [label, setLabel] = useState("");
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (constant) {
      setLabel(constant.label || "");
      setKey(constant.key);
      setValue(constant.value);
    } else {
      setLabel("");
      setKey("");
      setValue("");
    }
  }, [constant]);

  const handleSave = async () => {
    if (!label || !key || !value) {
      toast({
        title: "All fields are required.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      if (constant) {
        await constantsService.updateConstant(constant.id, { label, key, value });
        toast({
          title: "Constant updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await constantsService.createConstant({ label, key, value });
        toast({
          title: "Constant created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      fetchConstants();
      onClose();
    } catch (error) {
      toast({
        title: "Error saving constant.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{constant ? "Edit Constant" : "Add Constant"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="label" mb={4} isRequired>
            <FormLabel>Label</FormLabel>
            <Input 
              placeholder="Enter label" 
              value={label} 
              onChange={(e) => setLabel(e.target.value)} 
            />
          </FormControl>
          <FormControl id="key" mb={4} isRequired>
            <FormLabel>Key</FormLabel>
            <Input 
              placeholder="Enter key" 
              value={key} 
              onChange={(e) => setKey(e.target.value)} 
            />
          </FormControl>
          <FormControl id="value" mb={4} isRequired>
            <FormLabel>Value</FormLabel>
            <Input 
              placeholder="Enter value" 
              value={value} 
              onChange={(e) => setValue(e.target.value)} 
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button 
            colorScheme="primary" 
            onClick={handleSave} 
            isLoading={isLoading} 
            loadingText="Saving"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConstantModal;
