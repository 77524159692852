import axiosInstance from "../utils/axios";

export const reasonsService = {
  getAllReasons: async () => {
    try {
      return axiosInstance.get(`/settings/reasons`);
    } catch (error) {
      throw error;
    }
  },

  getReasonById: async (id) => {
    try {
      return axiosInstance.get(`/settings/reasons/${id}`);
    } catch (error) {
      throw error;
    }
  },

  createReason: async (data) => {
    try {
      return axiosInstance.post(`/settings/reasons`, data);
    } catch (error) {
      throw error;
    }
  },

  updateReason: async (id, data) => {
    try {
      return axiosInstance.put(`/settings/reasons/${id}`, data);
    } catch (error) {
      throw error;
    }
  },

  deleteReason: async (id) => {
    try {
      return axiosInstance.delete(`/settings/reasons/${id}`);
    } catch (error) {
      throw error;
    }
  }
};
