import axiosInstance from "../utils/axios";

export const tripsService = {
  getTrips: async (itemsPerPage, currentPage,status,tripStatus,startDate, endDate ) => {
    try {
      let url =  `/admins/bookings?limit=${itemsPerPage}&page=${currentPage}&status=${status}`
      if(tripStatus){
        url+=`&tripStatus=${tripStatus}`;
      }
      if(startDate && endDate){
        url+=`&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
      }
      return axiosInstance.get(
       url
      );
    } catch (error) {
      throw error;
    }
  },
  geSpecialRequests: async (itemsPerPage, currentPage,status,startDate, endDate) => {
    try {
      let url =  `/admins/specialRequests?limit=${itemsPerPage}&currentPage=${currentPage}`
      if(status && status!='ALL'){
        url+=`&status=${status}`;
      }
      if(startDate && endDate){
        url+=`&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
      }
      return axiosInstance.get(
       url
      );
    } catch (error) {
      throw error;
    }
  },
  getDriverTrips: async (itemsPerPage, currentPage,status,driverId) => {
    try {
      return axiosInstance.get(
        `/statistics/driverTrips?limit=${itemsPerPage}&page=${currentPage}&status=${status}&driverId=${driverId}`
      );
    } catch (error) {
      throw error;
    }
  },
  rejectTrip: async (data) => {
    try {
      return axiosInstance.put("/booking/adminAcceptOrRejectRequest/", data);
    } catch (error) {
      throw error;
    }
  },
};
