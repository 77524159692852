
import axiosInstance from "../utils/axios";

export const constantsService = {
  getAllConstants: async () => {
    try {
      return axiosInstance.get(`/constants`);
    } catch (error) {
      throw error;
    }
  },

  getConstantByKey: async (key) => {
    try {
      return axiosInstance.get(`/constants/${key}`);
    } catch (error) {
      throw error;
    }
  },

  createConstant: async (data) => {
    try {
      return axiosInstance.post(`/constants`, data);
    } catch (error) {
      throw error;
    }
  },
  updateConstant: async (id, data) => {
    try {
      return axiosInstance.put(`/constants/${id}`, data);
    } catch (error) {
      throw error;
    }
  },
  deleteConstant: async (id) => {
    try {
      return axiosInstance.delete(`/constants/${id}`);
    } catch (error) {
      throw error;
    }
  },
};
