import { useEffect, useState } from "react";
import user from "../../assets/images/user.png";
import logo from "../../assets/images/white-logo.png";
import Header from "./header";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi";

export default function AppLayout({ children, showBackButton }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const links = [
    {
      href: "#",
      icon: "bx bx-grid-alt",
      name: "Dashboard",
      url: "/dashboard",
      key: "/dashboard",
      newMenu: true,
    },
    {
      href: "#",
      icon: "bx bx-car",
      name: "Vehicles",
      url: "/vehicles?page=1",
      key: "/vehicles",
    },
    {
      href: "#",
      icon: "bx bx-user",
      name: "Drivers",
      url: "/drivers?page=1",
      key: "/drivers",
    },
    {
      href: "#",
      icon: "bx bx-user-circle",
      name: "Passengers",
      key: "/passengers",
      url: "/passengers?page=1",
    },
    {
      href: "#",
      icon: "bx bx-user-check",
      name: "Users",
      url: "/users?page=1",
      key: "/users",
    },
    {
      href: "#",
      icon: "bx bx-circle",
      name: "Trips",
      url: "/trips?page=1&tab=0",
      key: "/trips",
    },
    {
      href: "#",
      icon: "bx bx-cog",
      name: "Settings",
      url: "/settings",
      newMenu: true,
      key: "/settings",
    },
    {
      href: "#",
      icon: "bx bx-log-out",
      name: "Sign Out",
      url: "/logout",
      key: "/logout",
    },
  ];

  const [isMdDesktop, setIsMdDesktop] = useState(false);

  const toggleMenu = () => {
    const sidebar = document.querySelector(".sidebar");
    if (sidebar.classList.contains("open")) {
      setIsMdDesktop(true);
    } else {
      setIsMdDesktop(false);
    }
    sidebar.classList.toggle("open");
  };

  const collapse = () => {
    toggleMenu();
    setIsMdDesktop(!isMdDesktop);
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      navigate("/logout");
    }
  }, []);

  return (
    <div>
      <div className="sidebar open">
        <div className="logo-details">
          <div className="logo_name">
           <a href="/admin/dashboard">
           <img
              src={logo}
              style={{ width: "75%", height: "75%", objectFit: "cover" }}
            />
           </a>
          </div>
        </div>
        <ul className="nav-list">
          <Box pt={"10px"}></Box>
          {links.map((link, index) => (
            <li key={index}>
              {link.newMenu && index != 0 && (
                <Box w="100%" py="24px">
                  <Box w="100%" h="1px" bg="neutral.600" />
                </Box>
              )}
              {link.newMenu && (
                <Box
                  w={"100%"}
                  alignSelf="center"
                  color="text.lightest"
                  px="16px"
                  pt="10px"
                  pb="16px"
                  borderRight={"solid 1px #00000000"}
                  height={50}
                >
                  <Flex
                    alignItems={"center"}
                    justifyContent={isMdDesktop ? "center" : "space-between"}
                    color={`"text.lightest"}`}
                    gap="18px"
                    px={isMdDesktop ? "20px" : "30px"}
                    py="14px"
                    w={"100%"}
                    overflow={"hidden"}
                  >
                    {isMdDesktop ? (
                      <></>
                    ) : (
                      <Text whiteSpace={"nowrap"} overflow={"hidden"}>
                        {index == 0 ? " MAIN MENU" : "OTHERS"}
                      </Text>
                    )}
                    {index == 0 && (
                      <Text
                        onClick={() => {
                          collapse();
                        }}
                        cursor={"pointer"}
                        _hover={{ color: "text.light" }}
                      >
                        {isMdDesktop ? (
                          <HiOutlineChevronDoubleRight
                            size={18}
                          ></HiOutlineChevronDoubleRight>
                        ) : (
                          <HiOutlineChevronDoubleLeft
                            size={18}
                          ></HiOutlineChevronDoubleLeft>
                        )}
                      </Text>
                    )}
                  </Flex>
                </Box>
              )}
              <Link
                className={
                  pathname.startsWith(link.key) ? "activeSidebarItem" : ""
                }
                to={link.url}
              >
                <i className={link.icon}></i>
                <span className="links_name">{link.name}</span>
              </Link>
              <span className="tooltip">{link.name}</span>
            </li>
          ))}
        </ul>
      </div>
      <section className="home-section " style={{ backgroundColor: "#F2F4F6" }}>
        <Header
          showBackButton={showBackButton ? true : false}
          isMdDesktop={isMdDesktop}
        ></Header>
        <Box
          pb={"20px"}
          h={"100vh"}
          overflow={"auto"}
          bg="neutral.300"
          pt={"70px"}
        >
          {children}
        </Box>
      </section>
    </div>
  );
}
