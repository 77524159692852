import { useEffect, useState } from "react";
import { useToast, Box } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { tripsService } from "../../services/trips";
import AcceptedTripsTable from "./accepted-trips-table";

const DriverAcceptedTrips = ({ driverId }) => {
  const itemsPerPage = 10;
  const [pageNum, setPageNum] = useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [totalNum, setTotalNum] = useState(0);

  useEffect(() => {
    const tab = searchParams.get("tab");
    const page = searchParams.get("page");
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate(`/drivers/performance?page=1&tab=${tab}`);
  }, [searchParams]);

  const headers = [
    {
      name: "Passenger",
    },
    {
      name: "Pickup",
    },
    {
      name: "Drop-off",
    },
    {
      name: "Vehicle",
    },
    {
      name: "Amount",
    },
    {
      name: "Trip Status",
    },
    {
      name: "Booking Type",
    },
    {
      name: "Trip Date",
      sortable: true,
    },
    {
      name: "Action",
    },
  ];

  const getTrips = async (limit, pageNum) => {
    try {
      setLoading(true);
      const res = await tripsService.getDriverTrips(
        limit,
        pageNum,
        "ACCEPTED",
        driverId
      );
      setTrips(res?.data?.trips);
      setTotalNum(res.data?.totalTrips);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Failed to retrieve trips",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    if (driverId && pageNum) {
      getTrips(itemsPerPage, pageNum);
    }
  }, [pageNum, driverId]);

  return (
    <>
      <Box mx="0px">
        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <AcceptedTripsTable
            headers={headers}
            data={trips}
            loading={loading}
            totalNum={totalNum}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            setSortBy={"created_at"}
            searching={false}
            driverId={driverId}
          />
        </Box>
      </Box>
    </>
  );
};

export default DriverAcceptedTrips;
