import AppRoutes from "./routes";
import "./styles/main.css";
import "./styles/sidebar.css";
import "./styles/dashboard.css";

function App() {
  return <AppRoutes />;
}

export default App;
