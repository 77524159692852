import {
    Box,
    Center,
    Button,
    FormControl,
    FormLabel,
    Input,
    Text,
    Image,
    useToast,
    Heading,
  } from "@chakra-ui/react";
  import "./auth.css";
  import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
  import logo from "../../assets/images/white-logo.png";
  import { FiLogIn } from "react-icons/fi";
  import { useEffect, useState } from "react";
  import { authService } from "../../services/auth";
import { ColorRing } from "react-loader-spinner";
  
  const NewPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const toast = useToast();
    const [passError,setPassError] = useState(false);
    const location = useLocation();
    const [token,setToken] = useState(null)
  const searchParams = new URLSearchParams(location.search);
  
    const [data, setData] = useState({
      confirmPassword: undefined,
      password: undefined,
    });

    const verifyResetPasswordLink = async(link)=>{
        try{
            setLoadingPage(true)
const res = await authService.verifyResetPasswordLink({link: link});
setLoadingPage(false)
        }
        catch(error){
            setTimeout(()=>{
setLoadingPage(false)
            navigate("/")
            },1000)
            toast({
                position: "top",
                title: "Error",
                status: "error",
                description: "Invalid link",
                isClosable: true,
                duration: 2000,
              });
        }
    }

    useEffect(()=>{
      if(token){
        verifyResetPasswordLink(token)
      }
    },[token])
  
    useEffect(() => {
      const token = localStorage.getItem("access_token");
      if (token) {
        navigate("/dashboard");
      }
    }, []);

    useEffect(()=>{
   if(searchParams.get('token')){
    setToken(searchParams.get('token'))
   }else{
    navigate("/")
   }
    },[searchParams])
  
    const login = async (e) => {
      try {
        e.preventDefault();
        if (data.confirmPassword.length > 0 && data.password.trim().length > 0) {
          setLoading(true);
          const res = await authService.changePassword({...data,token: token});
          toast({
            position: "top",
            title: res?.data?.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          navigate("/");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast({
          position: "top",
          title: "Error",
          status: "error",
          description: error?.response?.data?.message || error?.message,
          isClosable: true,
          duration: 2000,
        });
      }
    };
  
    return (
      <Box
        backgroundColor={"rgb(28 36 52)"}
        minH={"100vh"}
        py={"100px"}
        w={"100%"}
      >
      {
        loadingPage?
<Center h={"400px"}>
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#3e98d4", "#3cb0fd", "#0973b9", "#3e98d4", "#0099ff"]}
              />
            </Center>
        :
         <Box>
       <Center w={"100%"} mb={"20px"}>
          <Image src={logo} w={"250px"} />
        </Center>
        <Center py={"20px"} w={"100%"}>
          <Center
            boxShadow="base"
            bg={"white"}
            className="auth-forms"
            w={"30%"}
            px={"30px"}
            pt={"30px"}
            pb={"20px"}
            rounded={"md"}
          >
            <Box w={"100%"} onSubmit={login} as={"form"}>
            <Center>
        <Heading color={'#000'} fontSize={'25px'} mb={'20px'}>New Password</Heading>
                </Center>
            <FormControl isRequired mb={"10px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Password
                </FormLabel>
                <Input
                  py={"22px"}
                  onChange={(e) => {
                    if(data?.confirmPassword!=e.target.value) {
                        setPassError(true)
                       }else{
                           setPassError(false)
                       }
                    setData({ ...data, password: e.target.value });
                  }}
                  type={"password"}
                  placeholder="Password"
                />
              </FormControl>
  
              <FormControl isRequired mb={"30px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                 Confirm Password
                </FormLabel>
                <Input
                  py={"22px"}
                  onChange={(e) => {
                    if(data?.password!=e.target.value) {
                     setPassError(true)
                    }else{
                        setPassError(false)
                    }
                    setData({ ...data, confirmPassword: e.target.value });
                  }}
                  type={"password"}
                  placeholder="Confirm Password"
                />
                {
                   passError && data?.password?.length>0 && (
                        <Text fontSize={'15px'} color="#F43A4D">
                    {
                       (passError && !data?.confirmPassword)?
                       "Confirm password is required"
                        :
                        "Passwords don't match"
                    }
                </Text>
                    )
                }
              </FormControl>
  
              <Box>
                <Button
                  type={"submit"}
                  fontWeight={"bold"}
                  fontSize={"16px"}
                  backgroundColor={"#3e98d4"}
                  isLoading={loading ? true : false}
                  loadingText={"Submitting..."}
                  w={"100%"}
                  mb={"10px"}
                  _hover={{ backgroundColor: "#3e98d4" }}
                  color={"#fff"}
                  isDisabled={passError || !data?.password || !data?.confirmPassword}
                  py={"25px"}
                  display={"flex"}
                  borderRadius={"100px"}
                  columnGap={"5px"}
                >
                  Reset password
                </Button>
  
                <Center>
                  <Button background={"transparent"} color={"#3e98d4"}>
                    <Link to={"/"}>Back to login</Link>
                  </Button>
                </Center>
              </Box>
            </Box>
          </Center>
        </Center>
       </Box>
      }
      </Box>
    );
  };
  
  export default NewPassword;
  