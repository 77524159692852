import axiosInstance from "../utils/axios";

export const authService = {
  registerAdmin: async (data) => {
    try {
      return axiosInstance.post("/admins/", data);
    } catch (error) {
      throw error;
    }
  },
  loginAdmin: async (data) => {
    try {
      return axiosInstance.post("/admins/login", data);
    } catch (error) {
      throw error;
    }
  },
  forgotPassword: async (data) => {
    try {
      return axiosInstance.post("/admins/forgotPassword", data);
    } catch (error) {
      throw error;
    }
  },
  verifyResetPasswordLink: async (data) => {
    try {
      return axiosInstance.post("/admins/verifyResetPasswordLink",data);
    } catch (error) {
      throw error;
    }
  },
  changePassword: async (data) => {
    try {
      console.log("data",data)
      return axiosInstance.put("/admins/changePassword", data, {
        headers: {
          'Authorization': 'Bearer ' +data?.token
        }
      });
    } catch (error) {
      throw error;
    }
  },
  getCurrentAdmin: async () => {
    try {
      return axiosInstance.get(`/admins/currentAdmin`);
    } catch (error) {
      throw error;
    }
  },
};
