import React, { useEffect, useState } from "react";
import { 
  Box, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  Button, 
  IconButton, 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  useDisclosure, 
  Skeleton 
} from "@chakra-ui/react";
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import ConstantModal from "./constantModal";
import { constantsService } from "../../services/constants";
import { BsThreeDotsVertical } from "react-icons/bs";

const ConstantsTable = () => {
  const [constants, setConstants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedConstant, setSelectedConstant] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchConstants = async () => {
    setLoading(true); // Set loading state to true
    try {
      const response = await constantsService.getAllConstants();
      setConstants(response.data);
    } catch (error) {
      console.error("Error fetching constants", error);
    } finally {
      setLoading(false); // Set loading state to false after fetching
    }
  };

  useEffect(() => {
    fetchConstants();
  }, []);

  const handleAddConstant = () => {
    setSelectedConstant(null);
    onOpen();
  };

  const handleEditConstant = (constant) => {
    setSelectedConstant(constant);
    onOpen();
  };

  const handleDeleteConstant = async (id) => {
    // Confirm deletion with a message
    const confirmDelete = window.confirm("Are you sure you want to delete this constant? This action cannot be undone.");
    if (!confirmDelete) return; // Exit if the user cancels the deletion

    try {
      await constantsService.deleteConstant(id);
      fetchConstants();
    } catch (error) {
      console.error("Error deleting constant", error);
    }
  };

  return (
    <Box p={5}>
      <Button 
        leftIcon={<AiOutlinePlus />} 
        colorScheme="primary" 
        onClick={handleAddConstant} 
        mb={4}
      >
        Add Constant
      </Button>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Constant</Th>
            <Th>Value</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            // Display skeletons while loading
            Array.from({ length: 5 }).map((_, rowIndex) => (
              <Tr key={rowIndex}>
                {Array.from({ length: 3 }).map((_, colIndex) => (
                  <Td key={colIndex}>
                    <Box py={"7px"}>
                      <Skeleton
                        startColor={"#F4F4F4"}
                        borderRadius={"20px"}
                        endColor={"#dddddd"}
                        h={"20px"}
                      />
                    </Box>
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            constants.map((constant) => (
              <Tr key={constant.id}>
                <Td>{`${constant.label} (${constant.key})`}</Td>
                <Td>{constant.value}</Td>
                <Td>
                  <Menu>
                    <MenuButton as={IconButton} icon={<BsThreeDotsVertical />} />
                    <MenuList>
                      <MenuItem 
                        icon={<AiOutlineEdit />} 
                        onClick={() => handleEditConstant(constant)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem 
                        icon={<AiOutlineDelete />} 
                        color="red.500" 
                        onClick={() => handleDeleteConstant(constant.id)}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {isOpen && (
        <ConstantModal
          isOpen={isOpen}
          onClose={onClose}
          fetchConstants={fetchConstants}
          constant={selectedConstant}
        />
      )}
    </Box>
  );
};

export default ConstantsTable;
