import TripsTable from "./tripsTable";
import { useEffect, useState } from "react";
import { CiFilter, CiSearch } from "react-icons/ci";
import {
  Text,
  Input,
  useToast,
  Box,
  Center,
  Button,
  Flex,
  Spinner,
  MenuButton,
  Menu,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { tripsService } from "../../services/trips";
import { BsBarChartLine } from "react-icons/bs";
import { adminService } from "../../services/admins";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { BsExclamationCircleFill } from "react-icons/bs";
import { MdCloudDone } from "react-icons/md";
import { MdOutlinePending } from "react-icons/md";
import { BsPieChartFill } from "react-icons/bs";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import TripsTab from "./trips";
import SpecialRequests from "./special-requests";
import BookingsTab from "./bookings";
import { Calendar, DateRangePicker } from 'react-date-range';
import { MdDateRange } from "react-icons/md";
import moment from "moment/moment";

const Trips = () => {
  const itemsPerPage = 10;
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [currentOption,setCurrentOption] = useState(null)
  const [loadingStatistics, setLoadingStatistics] = useState(true);
  const [statistics, setStatistics] = useState({
    totalTrips: 0,
    totalFinishedTrips: 0,
    totalPendingTrips: 0,
    totalRejectedTrips: 0,
    totalAmount: 0,
  });

  const values = [
    {label: "All", value: 'ALL'},{label: "Pending",value: "PENDING"}, {label: "Finished", value: "FINISHED"}, {label: "Rejected", value: "REJECTED"}, {label: "Cancelled", value: "CANCELLED"}
  ]
  const values2 = [
    {label: "All", value: 'ALL'},{label: "Pending",value: "PENDING"}, {label: "Completed", value: "COMPLETED"}, {label: "Rejected", value: "REJECTED"}
  ]
  const [filter,setFilter] = useState(values)

  const [boxes, setBoxes] = useState([
    {
      label: "Total Trips",
      icon: <BsPieChartFill size={"30px"} color={"#3e98d4"} />,
      key: "totalTrips",
    },
    {
      label: "Finished Trips",
      icon: <MdCloudDone size={"30px"} color={"#0ED27D"} />,
      key: "totalFinishedTrips",
    },
    {
      label: "Pending Trips",
      icon: <MdOutlinePending size={"30px"} color={"#2B6FF2"} />,
      key: "totalPendingTrips",
    },
    {
      label: "Rejected Trips",
      icon: <BsExclamationCircleFill size={"30px"} color={"#F43A4D"} />,
      key: "totalRejectedTrips",
    },
    {
      label: "Total Amount",
      icon: <RiMoneyDollarCircleFill size={"30px"} color={"#F7A01F"} />,
      key: "totalAmount",
    },
  ]);

  const getTripsStatistics = async (noload = false) => {
    try {
      setLoadingStatistics(noload ? false : true);
      const res = await adminService.getTripsStatistics();
      setStatistics({
        totalTrips: res?.data?.totalTrips,
        totalFinishedTrips: res?.data?.totalFinishedTrips,
        totalPendingTrips: res?.data?.totalPendingTrips,
        totalRejectedTrips: res?.data?.totalRejectedTrips,
        totalAmount: res?.data?.totalAmount,
      });
      setLoadingStatistics(false);
    } catch (error) {
      console.log(error);
      setLoadingStatistics(false);
    }
  };

  useEffect(() => {
    getTripsStatistics();
  }, []);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const tab = searchParams.get("tab");
    if(tab == 2){
      setFilter(values2)
    }else{
      setFilter(values)
    }
    setTabIndex(parseInt(tab));
  }, [searchParams]);

  const onChangeTab = (index) => {
    const page = searchParams.get("page");
    setReset(true);
    setLabel('');
    setCurrentOption('ALL')
    setSelectionRange(
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    )
    navigate(`/trips?page=1&tab=${index}&status=ALL`);
    if(index==2){
      setFilter(values2)
    }else{
      setFilter(values)
    }
  };

  useEffect(() => {
    const status = searchParams.get("status");
    setCurrentOption(status || 'ALL')
  }, [searchParams]);

  const [reset,setReset] = useState(true)
  const [selectionRange,setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const [label,setLabel] = useState('')

  const handleSelect = (ranges)=>{
    setReset(false)
    setSelectionRange(ranges?.selection)

    const startDate = moment(ranges?.selection?.startDate);
    const endDate = moment(ranges?.selection?.endDate);
    const today = moment();

    if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day')) {
      setLabel('Today');
    }
    else if (startDate.isSame(today.clone().subtract(1, 'days'), 'day') && endDate.isSame(today.clone().subtract(1, 'days'), 'day')) {
      setLabel('Yesterday');
    }
    else if (startDate.isSame(today, 'week') && endDate.isSame(today, 'week')) {
      setLabel('This Week');
    }
    else if (startDate.isSame(today.clone().subtract(1, 'week'), 'week') && endDate.isSame(today.clone().subtract(1, 'week'), 'week')) {
      setLabel('Last Week');
    }
    else if (startDate.isSame(today, 'month') && endDate.isSame(today, 'month')) {
      setLabel('This Month');
    }
    else if (startDate.isSame(today.clone().subtract(1, 'month'), 'month') && endDate.isSame(today.clone().subtract(1, 'month'), 'month')) {
      setLabel('Last Month');
    }
    else {
      setLabel(`From ${startDate.format('MMM DD, YYYY')} to ${endDate.format('MMM DD, YYYY')}`);
    }
  }

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Flex
          flexWrap={"wrap"}
          rowGap={"15px"}
          justifyContent={"space-between"}
          mb={"30px"}
          columnGap={"23px"}
        >
          {boxes?.map((box, index) => {
            return (
              <Box
                flex={1}
                key={"statistics_" + index}
                bg={"white"}
                p={"20px"}
                px={"40px"}
                rounded={"md"}
                boxShadow={"xs"}
              >
                <Box mb={"10px"}>{box?.icon}</Box>
                <Text color={"#788698"} fontSize={17}>
                  {box?.label}
                </Text>
                {loadingStatistics ? (
                  <Box pt={"5px"}>
                    <Spinner size={"md"} color={"#3e98d4"} />
                  </Box>
                ) : (
                  <Text color={"#000"} fontSize={22} fontWeight={"bold"}>
                    {index == 4 && "$ "}
                    {statistics[box.key]?.toLocaleString()}
                  </Text>
                )}
              </Box>
            );
          })}
        </Flex>

        <Tabs
          index={tabIndex}
          onChange={onChangeTab}
          variant="soft-rounded"
          colorScheme="primary"
        >
          <TabList justifyContent={'space-between'}>
            <Flex columnGap={'10px'}>
            <Tab>Trips</Tab>
            <Tab>Bookings</Tab>
            <Tab>Special Requests</Tab>
            </Flex>
            <Box>
            <Flex columnGap={'10px'}>

            <Menu >
          <MenuButton
            as={IconButton}
            aria-label="Options"
            h={"100% !important"}
            fontSize={"16px"}
            paddingBottom={"0px"}
            backgroundColor={"transparent !important"}
            borderRadius={"0px !important"}
            borderRight={"none !important"}
            borderBottom={"none !important"}
            border={"none !important"}
            variant="outline"
          >
            <Flex
              py={"10px"}
              px={"15px"}
              bg={"white"}
              borderRadius={"10px"}
              borderColor={"#F8FAFB"}
              borderWidth={1}
              alignItems={"center"}
              columnGap={"10px"}
            >
              <Text>{label? label: 'Date'}</Text>
              <Text>
                <MdDateRange size={20}></MdDateRange>
              </Text>
            </Flex>
          </MenuButton>
          <MenuList boxShadow="xl" rounded="md">
          <Flex justifyContent={'flex-end'}>
              <Button size={'sm'} onClick={()=>{
                setReset(true);
                setLabel('');
                setSelectionRange(
                  {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection',
                  }
                )
                }} colorScheme="primary">Reset</Button>
            </Flex>
          <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
      />
          </MenuList>
        </Menu>

            <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            h={"100% !important"}
            fontSize={"16px"}
            paddingBottom={"0px"}
            backgroundColor={"transparent !important"}
            borderRadius={"0px !important"}
            borderRight={"none !important"}
            borderBottom={"none !important"}
            border={"none !important"}
            variant="outline"
          >
            <Flex
              py={"10px"}
              px={"15px"}
              bg={"white"}
              borderRadius={"10px"}
              borderColor={"#F8FAFB"}
              borderWidth={1}
              alignItems={"center"}
              columnGap={"10px"}
            >
              <Text>{filter?.find((f)=>f?.value == currentOption)?.label}</Text>
              <Text>
                <CiFilter size={20}></CiFilter>
              </Text>
            </Flex>
          </MenuButton>
          <MenuList boxShadow="xl" rounded="md">
            {filter?.map((_filter) => {
              return (
                <MenuItem
                  className={currentOption == _filter?.value ? "activeMenuList" : ""}
                  onClick={() => {
    const tab = searchParams.get("tab");
                    if(_filter?.value){
                 navigate(`/trips?page=1&tab=${tab}&status=${_filter?.value}`)
                    }else{
                 navigate(`/trips?page=1&tab=${tab}&status=ALL`)
                    }
                  }}
                  style={{ fontSize: 15 }}
                >
                  <Flex columnGap={"15px"} alignItems={"center"}>
                    <Box
                     className={`active-dot ${
                      currentOption == _filter?.value ? "active-dropdown" : ""
                    }`}
                    ></Box>
                    {_filter?.label}
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
        </Flex>
            </Box>
          </TabList>
          <TabPanels>
            <TabPanel>{tabIndex == 0 && <TripsTab startDate={reset? null : selectionRange?.startDate} endDate={reset? null :selectionRange?.endDate} currentOption={currentOption} />}</TabPanel>
            <TabPanel>{tabIndex == 1 && <BookingsTab startDate={reset? null : selectionRange?.startDate} endDate={reset? null :selectionRange?.endDate} currentOption={currentOption} />}</TabPanel>
            <TabPanel>{tabIndex == 2 && <SpecialRequests startDate={reset? null : selectionRange?.startDate} endDate={reset? null :selectionRange?.endDate} currentOption={currentOption} />}</TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default Trips;
