import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  Center,
  Skeleton,
  useToast,
  useDisclosure,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { RiDatabase2Fill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import TablePagination from "../../components/common/tablePagination";
import { vehicleTypes } from "../../utils/helpers";
import { TbDotsVertical } from "react-icons/tb";
import DisableOrDeleteModal from "../drivers/disableDeleteUser";

const PassengersTable = ({
  headers,
  data,
  loading,
  totalNum,
  pageNum,
  setSortBy,
  searching,
  itemsPerPage,
  onReload
}) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [user,setUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();
  const [action,setAction] = useState('')

  useEffect(() => {
    setCurrentPage(pageNum);
  }, [pageNum]);

  const pagNation = useRef();

  const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <Box minH={"60vh"}>
      <Table className="customers-table">
        <Thead>
          <Tr>
            {headers?.map((header, index) => (
              <Th textTransform={"none"}>
                <Text fontWeight={"bold"}>{header?.name} </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            rows?.map((r) => {
              return (
                <>
                  <Tr>
                    {Array.from(
                      { length: headers?.length },
                      (_, i) => i + 1
                    )?.map((d) => {
                      return (
                        <Td>
                          <Box py={"7px"}>
                            <Skeleton
                              startColor={"#F4F4F4"}
                              borderRadius={"20px"}
                              endColor={"#dddddd"}
                              h={"20px"}
                            />
                          </Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </>
              );
            })
          ) : (
            <>
              {!loading && data?.length == 0 ? (
                <>
                  <Tr _hover={{ bg: "white !important" }}>
                    <Td colSpan={12} bg={"white"} _hover={{ bg: "white" }}>
                      <Center bg={"white"} py={"45px"} w={"100%"} minW={"100%"}>
                        {searching ? (
                          <Text>No results found</Text>
                        ) : (
                          <>
                            <Center>
                              <Box textAlign={"center"}>
                                <Center mb={"18px"}>
                                  <Box
                                    py={6}
                                    px={6}
                                    borderRadius={"10px"}
                                    bg={"#F2F4F6"}
                                  >
                                    <RiDatabase2Fill
                                      color={"#bcc5d1"}
                                      size={35}
                                    ></RiDatabase2Fill>
                                  </Box>
                                </Center>
                                <Text
                                  fontSize={"19px"}
                                  fontWeight={500}
                                  mb={"9px"}
                                  color={"#637184"}
                                >
                                  Passengers
                                </Text>
                                <Text fontSize={"14px"} color="text.lightest">
                                  No passengers available, once they are
                                  available they will appear right here.
                                </Text>
                              </Box>
                            </Center>
                          </>
                        )}
                      </Center>
                    </Td>
                  </Tr>
                </>
              ) : (
                <>
                  {data?.map((data) => {
                    return (
                      <Tr>
                        <Td>{data?.fullName}</Td>
                        <Td>{data?.phoneNumber}</Td>
                        <Td>{data?.email}</Td>
                        <Td>{data?._count?.trips}</Td>
                        <Td>
                          <Box
                            textAlign={"center"}
                            color={"#fff"}
                            bg={
                              data?.accountStatus == "ACTIVE"
                                ? "#0ED27D"
                                : data?.accountStatus == "PENDING"
                                ? "#F7A01F"
                                : "#F43A4D"
                            }
                            py={2.5}
                            px={2}
                            borderRadius={8}
                          >
                            {data?.accountStatus}
                          </Box>
                        </Td>
                        <Td>
                          {new Date(data?.createdAt).toLocaleDateString() +
                            " - " +
                            new Date(data?.createdAt).toLocaleTimeString()}
                        </Td>
                        <Td>
                        <Menu>
                            <MenuButton bg={"transparent"} as={Button}>
                              <TbDotsVertical size={22} />
                            </MenuButton>
                            <MenuList>
                        {
                              data?.accountStatus != 'DELETED' && (
                                <MenuItem
                                onClick={() => {
                                setIsModalVisible(true);
                                setUser(data);
                                setAction('disable')
                                }}
                              >
                              {data?.accountStatus=='DISABLED'?  'Enable Passenger' : 'Disable Passenger'}
                              </MenuItem>
                              )
                             }
                              {
                                data?.accountStatus != 'DELETED' && (
                                  <MenuItem
                                  onClick={() => {
                                    setIsModalVisible(true);
                                  setUser(data);
                                  setAction('delete')
                                  }}
                                >
                                Delete Passenger
                                </MenuItem>
                                )
                              }
                              </MenuList>
                              </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Tbody>
      </Table>

      {totalNum > itemsPerPage ? (
        <Box ref={pagNation} className="pag-cont bottom-0 w-full pb-20">
          <TablePagination
            length={totalNum}
            initialPage={parseInt(currentPage)}
            currentItems={data?.length}
            pageNum={pageNum}
            itemsPerPage={itemsPerPage}
            setPage={(page) => {
              setCurrentPage(parseInt(page));
              navigate(`/passengers?page=${page}`);
            }}
          />
        </Box>
      ) : (
        <></>
      )}

{
  isModalVisible && (
<DisableOrDeleteModal
        isOpen={isModalVisible}
        status={action=='delete'? 'DELETED' : user?.accountStatus =='DISABLED'? 'ACTIVE' : 'DISABLED'}
        onOpen={()=>{setIsModalVisible(true)}}
        onClose={()=>{
          setIsModalVisible(false);
        }}
        btnTitle={action=='delete'? 'Delete' : user?.accountStatus == 'DISABLED'? 'Enable' :'Disable'}
        description={action=='delete'? `Are you sure you want to delete this passenger? This can't be undo` : `Are you sure you want to ${user?.accountStatus == 'DISABLED'? 'enable':'disable'} this passenger?`}
        title={action=='delete'? `Delete Passenger` : user?.accountStatus == 'DISABLED'? 'Enable Passenger' : `Disable Passenger`}
        userId={user?.id}
        onSuccess={(message) => {
          toast({
            position: "top",
            title: "Success",
            status: "success",
            description: message,
            isClosable: true,
            duration: 2000,
          });
          setIsModalVisible(false);
          onReload()
        }}
        onError={(message) => {
          toast({
            position: "top",
            title: "Error",
            status: "error",
            description: message,
            isClosable: true,
            duration: 2000,
          });
        }}
      />
  )
}
    </Box>
  );
};

export default PassengersTable;
