import axiosInstance from "../utils/axios";

export const adminService = {
  getAdmins: async (itemsPerPage, currentPage) => {
    try {
      return axiosInstance.get(
        `/admins?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
      );
    } catch (error) {
      throw error;
    }
  },
  getPassengers: async (itemsPerPage, currentPage) => {
    try {
      return axiosInstance.get(
        `/admins/passengers?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
      );
    } catch (error) {
      throw error;
    }
  },
  getTripsStatistics: async () => {
    try {
      return axiosInstance.get(`/admins/tripsStatistics`);
    } catch (error) {
      throw error;
    }
  },
  assignDriver: async (data) => {
    try {
      return axiosInstance.put(`/admins/assignDriver`, data);
    } catch (error) {
      throw error;
    }
  },
  getDriversByTripId: async (id) => {
    try {
      return axiosInstance.get(`/admins/driversByTripId/${id}`);
    } catch (error) {
      throw error;
    }
  },
  rejectRequest: async (data) => {
    try {
      return axiosInstance.put(`/admins/specialRequests/reject`, data);
    } catch (error) {
      throw error;
    }
  },
  completeRequest: async (data) => {
    try {
      return axiosInstance.put(`/admins/specialRequests/complete`, data);
    } catch (error) {
      throw error;
    }
  },
  updatePassword: async (data) => {
    try {
      return axiosInstance.put("/admins/updatePassword", data);
    } catch (error) {
      throw error;
    }
  },
  deletePricing: async (id) => {
    try {
      return axiosInstance.delete(`/admins/deletePricingData?id=${id}`);
    } catch (error) {
      throw error;
    }
  },
};
