import {
    Box,
    Center,
    Button,
    FormControl,
    FormLabel,
    Input,
    Text,
    Image,
    useToast,
    Heading,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    AlertDialogFooter,
  } from "@chakra-ui/react";
  import "./auth.css";
  import { Link, useNavigate } from "react-router-dom";
  import logo from "../../assets/images/white-logo.png";
  import { FiLogIn } from "react-icons/fi";
  import { useEffect, useState } from "react";
  import { authService } from "../../services/auth";
  
  const ForgotPassword = () => {
    // const { loginAdmin, loading } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [sent,setSent] = useState(false);
  
    const [data, setData] = useState({
      email: undefined,
      password: undefined,
    });
  
    useEffect(() => {
      const token = localStorage.getItem("access_token");
      if (token) {
        navigate("/dashboard");
      }
    }, []);
  
    const login = async (e,type='initial') => {
      try {
        e.preventDefault();
        if (data.email.trim().length > 0) {
          setLoading(true);
          const res = await authService.forgotPassword(data);
          setSent(true)
          setLoading(false);
          if(type=='resend'){
            toast({
              position: "top",
              title: "Success",
              status: "success",
              description: "Email resent successfully!",
              isClosable: true,
              duration: 2000,
            });
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast({
          position: "top",
          title: "Error",
          status: "error",
          description: error?.response?.data?.message || error?.message,
          isClosable: true,
          duration: 2000,
        });
      }
    };
  
    return (
      <Box
        backgroundColor={"rgb(28 36 52)"}
        minH={"100vh"}
        py={"100px"}
        w={"100%"}
      >
        <Center w={"100%"} mb={"20px"}>
          <Image src={logo} w={"250px"} />
        </Center>
        {
          sent?
<Center>
  <Box w={'50%'}>
<Alert
  status='success'
  variant='subtle'
  flexDirection='column'
  rounded={'lg'}
  alignItems='center'
  justifyContent='center'
  textAlign='center'
>
  <AlertIcon boxSize='40px' mr={0} />
  <AlertTitle mt={4} mb={1} fontSize='lg'>
    Email sent!
  </AlertTitle>
  <AlertDescription maxWidth='sm'>
   We've sent email verification link to <span style={{fontWeight: 500}}>{data?.email}</span> successfully, please check your email to reset password
  </AlertDescription>

  <Box mt={'30px'}>
  <Button
                  type={"submit"}
                  fontWeight={"bold"}
                  fontSize={"16px"}
                  backgroundColor={"#3e98d4"}
                  isLoading={loading ? true : false}
                  loadingText={"Sending..."}
                  w={"100%"}
                  _hover={{ backgroundColor: "#3e98d4" }}
                  color={"#fff"}
                  px={'40px'}
                  onClick={(e)=>[
                    login(e,'resend')
                  ]}
                  py={"25px"}
                  display={"flex"}
                  borderRadius={"100px"}
                  columnGap={"5px"}
                >
                 Resend
                </Button>
                <Center mt={'20px'}>
                  <Button onClick={()=>{
                    setSent(false)
                  }} background={"transparent"} color={"#3e98d4"}>
                    Back
                  </Button>
                </Center>
  </Box>
</Alert>
</Box>
</Center>
          :
          <Center py={"20px"} w={"100%"}>
          <Center
            boxShadow="base"
            bg={"white"}
            className="auth-forms"
            w={"30%"}
            px={"30px"}
            pt={"30px"}
            pb={"20px"}
            rounded={"md"}
          >
            <Box w={"100%"} onSubmit={login} as={"form"}>
                <Center>
        <Heading color={'#000'} fontSize={'25px'} mb={'20px'}>Forgot Password</Heading>
                </Center>
              <FormControl isRequired mb={"30px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Email address
                </FormLabel>
                <Input
                  py={"22px"}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                  type={"email"}
                  placeholder="Email address"
                />
              </FormControl>
  
              <Box>
                <Button
                  type={"submit"}
                  fontWeight={"bold"}
                  fontSize={"16px"}
                  backgroundColor={"#3e98d4"}
                  isLoading={loading ? true : false}
                  loadingText={"Sending..."}
                  w={"100%"}
                  mb={"10px"}
                  _hover={{ backgroundColor: "#3e98d4" }}
                  color={"#fff"}
                  py={"25px"}
                  display={"flex"}
                  borderRadius={"100px"}
                  columnGap={"5px"}
                >
                  Send reset link
                </Button>
  
                <Center>
                  <Button background={"transparent"} color={"#3e98d4"}>
                    <Link to={"/"}>Back</Link>
                  </Button>
                </Center>
              </Box>
            </Box>
          </Center>
        </Center>
        }
      </Box>
    );
  };
  
  export default ForgotPassword;
  