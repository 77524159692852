import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
  Text,
  Image,
  Box,
  Radio,
  Center,
  Checkbox,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { vehiclesService } from "../../services/vehicles";
import { currencyCodes, vehicleTypes } from "../../utils/helpers";
import { driversService } from "../../services/drivers";
import { Spinner } from "@chakra-ui/react";
import { adminService } from "../../services/admins";
import CountUp from "../count-up";

export default function RequestInfoModal({
  isOpen,
  onClose,
  onReject,
  onComplete,
  request,
}) {
  return (
    <>
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Special Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box mb={"10px"}>
              <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                Phone number:
              </Text>
              <Text color={"#000"} fontSize={16}>
                {request?.user.phoneNumber}
              </Text>
            </Box>
            <Box mb={"10px"}>
              <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                Full name:
              </Text>
              <Text color={"#000"} fontSize={16}>
                {request?.user.fullName}
              </Text>
            </Box>
            <Box mb={"10px"}>
              <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                Description:
              </Text>
              <Text color={"#000"} fontSize={16}>
                {request?.description}
              </Text>
            </Box>

            {request?.status == "REJECTED" && (
              <Box>
                <Box mb={"10px"}>
                  <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                    Rejection reason:
                  </Text>
                  <Text color={"#000"} fontSize={16}>
                    {request?.rejectionReason}
                  </Text>
                </Box>
              </Box>
            )}
          </ModalBody>

          {request?.status == "PENDING" && (
            <ModalFooter columnGap={"15px"}>
              <Button
                onClick={() => {
                  onReject();
                }}
                color={"white"}
                colorScheme="danger"
              >
                Reject request
              </Button>
              <Button
                onClick={() => {
                  onComplete();
                }}
                color={"white"}
                colorScheme="primary"
              >
                Mark as completed
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
