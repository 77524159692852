import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
  Text,
  Image,
  Box,
  Radio,
  Center,
  Checkbox,
  VStack,
  Heading,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import ReactStars from "react-rating-stars-component";

export default function ReviewsModal({ isOpen, onClose }) {
  const reviews = [
    {
      username: "Emmy Dufitumukiza",
      date: "6/6/24",
      message: "This is an amazing product! Highly recommend it.",
    },
    {
      username: "Aime Smith",
      date: "5/12/24",
      message: "Great quality, exceeded my expectations.",
    },
    {
      username: "L Benitha",
      date: "5/12/24",
      message: "Great quality, exceeded my expectations.",
    },
    {
      username: "Sam Wilson",
      date: "4/15/24",
      message: "Good value for the price. Will buy again!",
    },
  ];
  const bars = [1, 2, 3, 4, 5];

  return (
    <>
      <Modal size={"lg"} isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={"bold"}>{"Ratings and Reviews"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex
              direction="column"
              align="center"
              justify="center"
              p={6}
              px={0}
              pt={3}
              w="100%"
            >
              <Flex
                w={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box w={"100%"}>
                  <Text
                    lineHeight={"25px"}
                    fontWeight={"bold"}
                    color={"#000"}
                    fontSize={"40px"}
                  >
                    4.5
                  </Text>
                  <ReactStars
                    count={5}
                    onChange={() => {}}
                    size={25}
                    isHalf={true}
                    color={"#BCC5D1"}
                    edit={false}
                    value={4.5}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                  <Text lineHeight={"10px"} color={"#000"}>
                    10 reviews
                  </Text>
                </Box>
                <Box w={"100%"}>
                  {bars?.map((bar, index) => {
                    return (
                      <Flex w={"100%"} alignItems={"center"} columnGap={"10px"}>
                        <Text w={"10px"}>{5 - index}</Text>
                        <Box
                          h={"10px"}
                          w={"100%"}
                          bg={"#E6EAEF"}
                          borderRadius={"20px"}
                        >
                          <Box
                            bg={"#F7A01F"}
                            borderRadius={"20px"}
                            h={"100%"}
                            w={
                              index == 0
                                ? "12%"
                                : index == 3
                                ? "80%"
                                : index == 4
                                ? "0%"
                                : "8%"
                            }
                            className="animated-bar"
                          ></Box>
                        </Box>
                      </Flex>
                    );
                  })}
                </Box>
              </Flex>
              <Box mt={"15px"} textAlign={"left"} w={"100%"}>
                <Text
                  mb={"10px"}
                  fontWeight={"700"}
                  color={"#000"}
                  fontSize={"17px"}
                >
                  Passenger reviews
                </Text>
              </Box>
              {reviews.map((review, index) => (
                <ReviewRow
                  key={index}
                  {...review}
                  isLast={index === reviews.length - 1}
                />
              ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const ReviewRow = ({ username, date, message, isLast }) => (
  <>
    <Flex alignItems="center" py={2} w="100%" maxW="500px">
      <Avatar name={username} round={true} size="40" />
      <VStack align="flex-start" ml={4} spacing={1}>
        <Heading size="xm">{username}</Heading>
        <Flex columnGap={"10px"} alignItems={"center"}>
          <ReactStars
            count={5}
            onChange={() => {}}
            size={20}
            isHalf={true}
            color={"#BCC5D1"}
            edit={false}
            value={3.5}
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffd700"
          />
          <Text fontSize="sm" color="gray.500">
            {date}
          </Text>
        </Flex>
        <Text fontSize="md">{message}</Text>
      </VStack>
    </Flex>
    {!isLast && <Divider />}
  </>
);
