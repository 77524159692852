import TripsTable from "./tripsTable";
import { useEffect, useState } from "react";
import { useToast, Box } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { tripsService } from "../../services/trips";

const BookingsTab = ({currentOption,startDate,endDate}) => {
  const itemsPerPage = 10;
  const [pageNum, setPageNum] = useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [totalNum, setTotalNum] = useState(0);

  useEffect(() => {
    const tab = searchParams.get("tab");
    const page = searchParams.get("page");
    const status = searchParams.get("status");
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate(`/trips?page=1&tab=${tab}&status=${status? status:'ALL'}`);
  }, [searchParams]);

  const headers = [
    {
        name: "Trip Date",
        sortable: true,
      },
    {
      name: "Passenger",
    },
    {
      name: "Pickup",
    },
    {
      name: "Drop-off",
    },
    {
      name: "Vehicle",
    },
    {
      name: "Amount",
    },
    {
      name: "Trip Status",
    },
    {
      name: "Ride Type",
    },
    {
      name: "Action",
    },
  ];

  const getTrips = async (limit, pageNum) => {
    try {
      setLoading(true);
      const res = await tripsService.getTrips(limit, pageNum,'LATER',currentOption,startDate,endDate);
      setTrips(res?.data?.trips);
      setTotalNum(res.data?.totalTrips);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Failed to retrieve trips",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    if(currentOption){
    getTrips(itemsPerPage, pageNum);
    }
  }, [pageNum,currentOption,startDate,endDate]);

  return (
    <>
      <Box mx="0px">
        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <TripsTable
            headers={headers}
            data={trips}
            loading={loading}
            totalNum={totalNum}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            status={currentOption}
            onReload={()=>{
    getTrips(itemsPerPage, pageNum);
            }}
            setSortBy={"created_at"}
            searching={false}
          />
        </Box>
      </Box>
    </>
  );
};

export default BookingsTab;
