import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    Box,
    Flex,
    Text,
    VStack,
    HStack,
    Skeleton,
  } from "@chakra-ui/react";
  import { IoSearch } from "react-icons/io5";
  import React, { useEffect, useState } from "react";
  import { driversService } from "../../services/drivers";
import { vehiclesService } from "../../services/vehicles";
  
  export default function AssignDriverModal({ isOpen, onClose,vehicleId,onSuccess,onError }) {
    const [loading, setLoading] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [searchKey, setSearchKey] = useState(null);
    const [searched,setSearched] = useState(false)
    const [assigning, setAssigning] = useState(false);
    const [_driver,setDriver] = useState(null)

    const assign = async (vehicleId,driverId) => {
        try {
            setAssigning(true);
            const res = await vehiclesService.assignDriver({
                vehicleId:vehicleId, driverId: driverId
            })
            setAssigning(false);
            onSuccess(res?.data?.message)
        } catch (error) {
            setAssigning(false);
          onError(error?.response?.data?.message || "An error occured, please try again");
        }
      };

  
    const searchDrivers = async () => {
      try {
        setSearched(true)
        setLoading(true);
        const res = await driversService.getDrivers(10, 1, null, searchKey);
        if(res?.data?.searchKey == searchKey){
        setDrivers(res?.data?.drivers);
        setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    useEffect(()=>{
      if(searchKey!=null){
        searchDrivers()
      }
    },[searchKey])

    useEffect(()=>{
     if(!loading && !searchKey){
      setLoading(false);
     }
    },[searchKey,loading])
  
    return (
      <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Driver</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mb={4}>
              <FormLabel>Search Driver</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <IoSearch color="gray.300" />
                </InputLeftElement>
                <Input
                autoFocus={true}
                  placeholder="Search by name or phone number"
                  value={searchKey}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                />
              </InputGroup>
            </FormControl>
  
  {
    searched &&
            <VStack spacing={4} align="stretch">
              {loading ? (
                <>
                  <Skeleton
                    startColor={"#F4F4F4"}
                    borderRadius={"md"}
                    endColor={"#dddddd"}
                    height={"60px"}
                  />
                  <Skeleton
                    startColor={"#F4F4F4"}
                    borderRadius={"md"}
                    endColor={"#dddddd"}
                    height={"60px"}
                  />
                  <Skeleton
                    startColor={"#F4F4F4"}
                    borderRadius={"md"}
                    endColor={"#dddddd"}
                    height={"60px"}
                  />
                </>
              ) : drivers?.length > 0 ? (
                drivers.map((driver) => (
                  <Box key={driver.id} borderWidth="1px" borderRadius="md" p={4}>
                    <HStack justify="space-between">
                      <Box>
                        <Text fontWeight="bold">{driver?.fullName}</Text>
                        <Text fontSize="sm" color="gray.500">{driver.phoneNumber}</Text>
                      </Box>
                      <Button
                       isLoading={assigning && driver?.id == _driver}
                       px={'20px'}
                       isDisabled={assigning && driver?.id != _driver}
                       loadingText={"Assigning..."}
                       onClick={()=>{
                        setDriver(driver?.id)
                        assign(vehicleId,driver?.id)
                       }}
                       colorScheme="standard" size="sm">
                        Assign 
                      </Button>
                    </HStack>
                  </Box>
                ))
              ) : (
                <Text>No drivers found</Text>
              )}
            </VStack>
            }
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} colorScheme="gray" mr={3}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  