import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TbDotsVertical } from "react-icons/tb";
import PricingModal from "../../components/common/pricingModal";
import { vehiclesService } from "../../services/vehicles";
import { vehicleTypes } from "../../utils/helpers";

const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [loading, setLoading] = useState(true);
  const tds = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const rows = [1, 2, 3, 4];
  const [pricing, setPricingData] = useState([]);
  const toast = useToast();

  const getPricingData = async () => {
    try {
      setLoading(true);
      const res = await vehiclesService.getPricingData();
      setPricingData(res?.data?.pricingData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPricingData();
  }, []);

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Box className="table-nav"></Box>
        {/* <Flex rowGap={"40px"}>
          <Box w={"100%"} bg={"white"} py={"20px"} px={"20px"}>
            <Text className="tb-title" mb={"15px"}>
             Dashboard
            </Text>

            
    </Box>
    </Flex> */}
      </Box>
    </>
  );
};

export default Dashboard;
