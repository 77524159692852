import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
  Text,
  Image,
  Box,
  Radio,
  Center,
  Checkbox,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export default function SuccessModal({
  isOpen,
  onOpen,
  onClose,
  onConfirm,
  title,
  description,
  isLoading,
  btnText,
  child,
}) {
  return (
    <>
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {description && <Text>{description}</Text>}
            {child}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              onClick={() => {
                onConfirm();
              }}
              color={"white"}
              colorScheme="primary"
            >
              {btnText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
