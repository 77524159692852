import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  Center,
  Skeleton,
  Button,
  useToast,
  MenuItem,
  MenuButton,
  Menu,
  MenuList,
} from "@chakra-ui/react";
import { act, useEffect, useRef, useState } from "react";
import { RiDatabase2Fill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import TablePagination from "../../components/common/tablePagination";
import { authService } from "../../services/auth";
import { driversService } from "../../services/drivers";
import DriverInfoModal from "../../components/common/driverInfoModal";
import { IoChevronDown } from "react-icons/io5";
import { TbDotsVertical } from "react-icons/tb";
import { socketConnection } from "../../utils/socket";
import { vehicleTypes } from "../../utils/helpers";
import VehiclesModal from "./vehiclesModal";
import DisableOrDeleteModal from "./disableDeleteUser";

const DriversTable = ({
  headers,
  data,
  loading,
  totalNum,
  pageNum,
  setSortBy,
  searching,
  itemsPerPage,
  onApprove,
  status,
  onReload
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [driver, setDriver] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [action,setAction] = useState('')
  const toast = useToast();
  useEffect(() => {
    setCurrentPage(pageNum);
  }, [pageNum]);

  const pagNation = useRef();

  const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <Box minH={"60vh"}>
      <Table className="customers-table drivers-table">
        <Thead>
          <Tr>
            {headers?.map((header, index) => (
              <Th textTransform={"none"}>
                <Text fontWeight={"bold"}>{header?.name} </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            rows?.map((r) => {
              return (
                <>
                  <Tr>
                    {Array.from(
                      { length: headers?.length },
                      (_, i) => i + 1
                    )?.map((d) => {
                      return (
                        <Td>
                          <Box py={"7px"}>
                            <Skeleton
                              startColor={"#F4F4F4"}
                              borderRadius={"20px"}
                              endColor={"#dddddd"}
                              h={"20px"}
                            />
                          </Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </>
              );
            })
          ) : (
            <>
              {!loading && data?.length == 0 ? (
                <>
                  <Tr _hover={{ bg: "white !important" }}>
                    <Td colSpan={12} bg={"white"} _hover={{ bg: "white" }}>
                      <Center bg={"white"} py={"45px"} w={"100%"} minW={"100%"}>
                        {searching ? (
                          <Text>No results found</Text>
                        ) : (
                          <>
                            <Center>
                              <Box textAlign={"center"}>
                                <Center mb={"18px"}>
                                  <Box
                                    py={6}
                                    px={6}
                                    borderRadius={"10px"}
                                    bg={"#F2F4F6"}
                                  >
                                    <RiDatabase2Fill
                                      color={"#bcc5d1"}
                                      size={35}
                                    ></RiDatabase2Fill>
                                  </Box>
                                </Center>
                                <Text
                                  fontSize={"19px"}
                                  fontWeight={500}
                                  mb={"9px"}
                                  color={"#637184"}
                                >
                                  Drivers
                                </Text>
                                <Text fontSize={"14px"} color="text.lightest">
                                  No drivers available, once they are available
                                  they will appear right here.
                                </Text>
                              </Box>
                            </Center>
                          </>
                        )}
                      </Center>
                    </Td>
                  </Tr>
                </>
              ) : (
                <>
                  {data?.map((data) => {
                    return (
                      <Tr
                      onClick={() => {
                        setDriver(data);
                        setIsModalVisible(true);
                      }}
                       cursor={'pointer'}>
                        <Td>
                          <div className="flex-gap">
                            <span>
                              <button
                                title={
                                  data.availabilityStatus == "ONLINE"
                                    ? "Online"
                                    : data.availabilityStatus == "OFFLINE"
                                    ? "Offline"
                                    : "Busy"
                                }
                                className={`account-status ${
                                  data.availabilityStatus == "ONLINE"
                                    ? "account-active"
                                    : data.availabilityStatus == "OFFLINE"
                                    ? "account-inactive"
                                    : "driver-busy"
                                }`}
                              ></button>
                            </span>
                            <span className="relative">{data?.fullName}</span>
                          </div>
                        </Td>
                        <Td>{data?.phoneNumber}</Td>
                        <Td>{data?.email || '-'}</Td>
                        <Td>
                          {
                            vehicleTypes.find(
                              (v) => v?.value == data?.vehicles[0]?.vehicleType
                            )?.label || '-'
                          }
                        </Td>
                        <Td>
                          {!data?.isPrivate ? (
                            <Box color={"#34A853"} fontWeight={"bold"}>
                              Internal
                            </Box>
                          ) : (
                            <Box color={"#2B6FF2"} fontWeight={"bold"}>
                              Private
                            </Box>
                          )}
                        </Td>
                        <Td>
                          <Box
                            textAlign={"center"}
                            color={"#fff"}
                            bg={
                              data?.accountStatus == "ACTIVE"
                                ? "#0ED27D"
                                : data?.accountStatus == "PENDING"
                                ? "#F7A01F"
                                : "#F43A4D"
                            }
                            py={2.5}
                            px={2}
                            borderRadius={8}
                          >
                            {data?.accountStatus}
                          </Box>
                        </Td>
                        <Td>
                          {new Date(data?.createdAt).toLocaleDateString() +
                            " - " +
                            new Date(data?.createdAt).toLocaleTimeString()}
                        </Td>
                        <Td onClick={(e) => e.stopPropagation()}>
                          <Menu>
                            <MenuButton bg={"transparent"} as={Button}>
                              <TbDotsVertical size={22} />
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  setDriver(data);
                                  setIsModalVisible(true);
                                }}
                              >
                                View Driver
                              </MenuItem>
                              {
                                data?.vehicles?.length==0 && (
                                  <MenuItem
                                  onClick={() => {
                                    setDriver(data);
                                    setIsModalVisible2(true);
                                  }}
                                >
                                  Assign Vehicle
                                </MenuItem>
                                )
                              }
                              <MenuItem
                                onClick={() => {
                                  navigate(`/drivers/performance/${data?.id}?page=1&tab=0`);
                                }}
                              >
                                Driver Performance
                              </MenuItem>
                             {
                              data?.accountStatus != 'DELETED' && (
                                <MenuItem
                                onClick={() => {
                                setIsModalVisible3(true);
                                setDriver(data);
                                setAction('disable')
                                }}
                              >
                              {data?.accountStatus=='DISABLED'?  'Enable Driver' : 'Disable Driver'}
                              </MenuItem>
                              )
                             }
                              {
                                data?.accountStatus != 'DELETED' && (
                                  <MenuItem
                                  onClick={() => {
                                    setIsModalVisible3(true);
                                  setDriver(data);
                                  setAction('delete')
                                  }}
                                >
                                Delete Driver
                                </MenuItem>
                                )
                              }
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Tbody>
      </Table>

      {totalNum > itemsPerPage ? (
        <Box ref={pagNation} className="pag-cont bottom-0 w-full pb-20">
          <TablePagination
            length={totalNum}
            initialPage={parseInt(currentPage)}
            currentItems={data?.length}
            pageNum={pageNum}
            itemsPerPage={itemsPerPage}
            setPage={(page) => {
              setCurrentPage(parseInt(page));
              if(!status){
              navigate(`/drivers?page=${page}`);
              }else{
              navigate(`/drivers?page=${page}&status=${status}`);
              }
            }}
          />
        </Box>
      ) : (
        <></>
      )}

      {
        isModalVisible && (
          <DriverInfoModal
          isOpen={isModalVisible}
          driver={driver}
          onRegister={() => {
            toast({
              position: "top",
              title: "Account Updated!",
              description: "Driver info updated successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onApprove();
            setIsModalVisible(false);
          }}
          onAssign={()=>{
            setIsModalVisible2(true)
          }}
          onApprove={(message) => {
            toast({
              position: "top",
              title: "Approved!",
              description: message,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            setIsModalVisible(false);
            onApprove();
          }}
          onError={(message) => {
            toast({
              position: "top",
              title: "Error",
              status: "error",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
        )
      }

      {
        isModalVisible2 && (
          <VehiclesModal
          isOpen={isModalVisible2}
          driverId={driver?.id}
          onClose={()=>{setIsModalVisible2(false)}}
          onSuccess={(message) => {
            setIsModalVisible(false)
            setIsModalVisible2(false)
            onReload();
            toast({
              position: "top",
              title: "Success",
              status: "success",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
          onError={(message) => {
            toast({
              position: "top",
              title: "Error",
              status: "error",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
          />
        )
      }


{
  isModalVisible3 && (
<DisableOrDeleteModal
        isOpen={isModalVisible3}
        status={action=='delete'? 'DELETED' : driver?.accountStatus =='DISABLED'? 'ACTIVE' : 'DISABLED'}
        onOpen={()=>{setIsModalVisible3(true)}}
        onClose={()=>{
          setIsModalVisible3(false);
        }}
        btnTitle={action=='delete'? 'Delete' : driver?.accountStatus == 'DISABLED'? 'Enable' :'Disable'}
        description={action=='delete'? `Are you sure you want to delete this driver? This can't be undo` : `Are you sure you want to ${driver?.accountStatus == 'DISABLED'? 'enable':'disable'} this driver?`}
        title={action=='delete'? `Delete Driver` : driver?.accountStatus == 'DISABLED'? 'Enable Driver' : `Disable Driver`}
        userId={driver?.id}
        onSuccess={(message) => {
          toast({
            position: "top",
            title: "Success",
            status: "success",
            description: message,
            isClosable: true,
            duration: 2000,
          });
          setIsModalVisible3(false);
          onReload()
        }}
        onError={(message) => {
          toast({
            position: "top",
            title: "Error",
            status: "error",
            description: message,
            isClosable: true,
            duration: 2000,
          });
        }}
      />
  )
}

    </Box>
  );
};

export default DriversTable;
