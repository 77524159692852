import VehiclesTable from "./vehiclesTable";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { Text, Input, useToast, Box, Center, Button } from "@chakra-ui/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterAdminModal from "../../components/common/registerAdminModal";
import { vehiclesService } from "../../services/vehicles";
import RegisterVehicleModal from "./registerVehicleModal";

const Vehicles = () => {
  const itemsPerPage = 10;
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Vehicles, setVehicles] = useState([]);
  const [totalNum, setTotalNum] = useState(0);

  useEffect(() => {
    const page = searchParams.get("page");
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate("/vehicles?page=1");
  }, [searchParams]);

  const headers = [
    {
      name: "Vehicle Type",
    },
    {
      name: "Plate Number",
    },
    {
      name: "Color",
    },
    {
      name: "Seats",
    },
    {
      name: "Driver",
    },
    {
      name: "Created At",
      sortable: true,
    },
    {
      name: "Action",
    },
  ];

  const getVehicles = async (limit, pageNum) => {
    try {
      setLoading(true);
      const res = await vehiclesService.getAllVehicles(limit, pageNum, null);
      setVehicles(res?.data?.vehicles);
      setTotalNum(res.data?.totalVehicles);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Failed to retrieve Vehicles",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    getVehicles(itemsPerPage, pageNum);
  }, [pageNum]);

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Box className="table-nav">
          <Box mb={"10px"} className="tb-title">
            <Text>Vehicles</Text>
          </Box>

          <Box className="search-cont-panel">
            <Center mb={"10px"} className="flex">
              <Input
                bg="#ffff"
                borderColor="#ffff"
                placeholder="Search..."
                colorScheme="primary"
                type={"text"}
                boxShadow="xs"
                className="search-field"
              />
              <Text className={"search-icon"}>
                <CiSearch size={20} />
              </Text>
            </Center>

            <Button
              type={"button"}
              fontSize={"16px"}
              onClick={() => {
                setIsModalVisible(true);
              }}
              colorScheme="primary"
              loadingText={"Signing in..."}
              mb={"10px"}
              _hover={{ backgroundColor: "#3e98d4" }}
              color={"#fff"}
              display={"flex"}
              borderRadius={"10px"}
              columnGap={"5px"}
            >
              <AiFillPlusCircle size={18} color={"white"}></AiFillPlusCircle>
              &nbsp;Register Vehicle
            </Button>
          </Box>
        </Box>

        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <VehiclesTable
            headers={headers}
            data={Vehicles}
            onReload={()=>{
          getVehicles(itemsPerPage, pageNum);
            }}
            loading={loading}
            totalNum={totalNum}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            setSortBy={"created_at"}
            searching={false}
          />
        </Box>
      </Box>

      <RegisterVehicleModal
        onRegister={() => {
          toast({
            position: "top",
            title: "Vehicle added!",
            description: "Vehicle registered successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setIsModalVisible(false);
          getVehicles(itemsPerPage, pageNum);
        }}
        onError={(message) => {
          toast({
            position: "top",
            title: "Error",
            status: "error",
            description: message,
            isClosable: true,
            duration: 2000,
          });
        }}
        isOpen={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
      />
    </>
  );
};

export default Vehicles;
