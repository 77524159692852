import axiosInstance from "../utils/axios";

export const vehiclesService = {
  getPricingData: async () => {
    try {
      return axiosInstance.get(`/vehicles/pricingData`);
    } catch (error) {
      throw error;
    }
  },
  getAllVehicles: async (itemsPerPage, currentPage,status) => {
    try {
      return axiosInstance.get(`/vehicles?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&status=${status}`);
    } catch (error) {
      throw error;
    }
  },
  editPricingData: async (data) => {
    try {
      return axiosInstance.put(`/vehicles/editPricingData`, data);
    } catch (error) {
      throw error;
    }
  },
  registerVehicle: async (data) => {
    try {
      return axiosInstance.post("/vehicles/", data);
    } catch (error) {
      throw error;
    }
  },
  assignDriver: async (data) => {
    try {
      return axiosInstance.put("/vehicles/assignDriver/", data);
    } catch (error) {
      throw error;
    }
  },
};
