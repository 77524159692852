import {
  Box,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import LineChart from "./line-chart";
import { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { MdOutlineStackedLineChart } from "react-icons/md";
import { HiUsers } from "react-icons/hi2";
import { BsBarChartLine } from "react-icons/bs";
import { LuLineChart } from "react-icons/lu";
import { driversService } from "../../services/drivers";
import { FcRating } from "react-icons/fc";
import { MdReviews } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import ReactStars from "react-rating-stars-component";
import TripsTab from "../trips/trips";
import SpecialRequests from "../trips/special-requests";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DriverAcceptedTrips from "./driver-accepted-trips";
import DriverRejectedTrips from "./driver-rejected-trips";
import { IoEye } from "react-icons/io5";
import ReviewsModal from "../../components/modals/reviews-modal";
import { ColorRing } from "react-loader-spinner";

export default function DriverPerformance() {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const params = useParams();
  const [monthlyTotalAmount, setMonthlyTotalAmount] = useState(0);
  const [totalTrips, setTotalTrips] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [loadingStatistics, setLoadingStatistics] = useState(true);
  const [statistics, setStatistics] = useState({
    totalActiveDrivers: 0,
    totalPrivateDrivers: 0,
    totalInternalDrivers: 0,
    totalOnlineDrivers: 0,
  });
  const [isOpen, setIsOpen] = useState(false);

  const [boxes, setBoxes] = useState([
    {
      label: "Total Trips",
      icon: <BsBarChartLine size={"30px"} color={"#3e98d4"} />,
      key: "totalTrips",
    },
    {
      label: "Earned Amount",
      icon: <RiMoneyDollarCircleFill size={"30px"} color={"#F7A01F"} />,
      key: "totalAmount",
    },
    {
      label: "Average Rating",
      icon: <FcRating size={"30px"} />,
      key: "totalPrivateDrivers",
    },
    {
      label: "Total Reviews",
      icon: <MdReviews size={"30px"} color={"#2B6FF2"} />,
      key: "totalReviews",
    },
  ]);

  const [driverId, setDriverId] = useState();

  const getDriversStatistics = async (noload = false) => {
    try {
      setLoadingStatistics(noload ? false : true);
      const res = await driversService.getDriverTripsAmount(driverId);
      console.log("resss -->", res?.data);
      setStatistics({
        totalTrips: res?.data?.totalTrips,
        totalPrivateDrivers: res?.data?.totalPrivateDrivers,
        totalAmount: "$ " + parseInt(res?.data?.totalAmount || 0),
        totalReviews: res?.data?.totalReviews || 0,
      });
      setLoadingStatistics(false);
    } catch (error) {
      console.log(error);
      setLoadingStatistics(false);
    }
  };

  const [monthlyData, setMonthlyData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMonthlyData = async () => {
    try {
      setLoading(true);
      const res = await driversService.getDriverMonthlyTrips(
        driverId,
        selectedYear
      );
      const totalTrips = res?.data?.reduce((sum, item) => sum + item?.trips, 0);
      const totalAmount = res?.data?.reduce(
        (sum, item) => sum + item.amount,
        0
      );
      console.log("monthly",res?.data)
      setTotalTrips(totalTrips);
      setMonthlyData(res?.data);
      setMonthlyTotalAmount(totalAmount);
      setLoading(false);
    } catch (error) {
      console.log("error", error)
      setLoading(false);
    }
  };

  useEffect(() => {
    if (driverId) {
      getDriversStatistics();
    }
  }, [driverId]);

  useEffect(() => {
    if (params?.id) {
      setDriverId(params?.id);
    }
  }, [params]);

  useEffect(() => {
    if (driverId && selectedYear) {
      getMonthlyData();
    }
  }, [driverId, selectedYear]);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const tab = searchParams.get("tab");
    setTabIndex(parseInt(tab));
  }, [searchParams]);

  const onChangeTab = (index) => {
    const page = searchParams.get("page");
    navigate(`/drivers/performance/${params?.id}?page=1&tab=${index}`);
  };

  return (
    <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
      <Flex
        mb={"20px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        w={"100%"}
      >
        <Box>
          <Text fontSize={18} color={"#788698"} fontWeight={"500"}>
            Monthly Trips and Earnings
          </Text>
          {!loading && (
            <Text fontWeight={"bold"} fontSize={20}>
              $ {monthlyTotalAmount?.toLocaleString()}
            </Text>
          )}
        </Box>

        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            h={"100% !important"}
            paddingBottom={"0px"}
            backgroundColor={"transparent !important"}
            borderRadius={"0px !important"}
            borderRight={"none !important"}
            borderBottom={"none !important"}
            border={"none !important"}
            variant="outline"
          >
            <Flex
              py={"10px"}
              px={"15px"}
              bg={"white"}
              borderRadius={"10px"}
              borderColor={"#F8FAFB"}
              borderWidth={1}
              alignItems={"center"}
              columnGap={"10px"}
            >
              <Text>{selectedYear}</Text>
              <Text>
                <FiChevronDown size={22}></FiChevronDown>
              </Text>
            </Flex>
          </MenuButton>
          <MenuList boxShadow="xl" rounded="md">
            {Array.from(
              { length: currentYear - 2020 + 1 },
              (_, i) => currentYear - i
            )?.map((year) => {
              return (
                <MenuItem
                  className={selectedYear == year ? "activeMenuList" : ""}
                  onClick={() => {
                    setSelectedYear(year);
                  }}
                  style={{ fontSize: 15 }}
                >
                  <Flex columnGap={"15px"} alignItems={"center"}>
                    <Box
                      className={`active-dot ${
                        selectedYear == year ? "active-dropdown" : ""
                      }`}
                    ></Box>
                    {year}
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Flex>
      <Box
        bg={"white"}
        w={"100%"}
        py={"20px"}
        px={"20px"}
        pb={"0px"}
        borderRadius={"10px"}
        borderColor={"#F8FAFB"}
        borderWidth={1}
      >
        <Flex
          position={"relative"}
          top={"20px"}
          justifyContent={"space-between"}
        >
          <Box></Box>
          {!loading && (
            <Flex pr={"20px"} alignItems={"center"} columnGap={"10px"}>
              <Box bg={"#3e98d4"} w={"40px"} h={"1.5px"}></Box>
              <Text fontSize={17} color={"#000"}>
                {totalTrips} trips{" "}
                {currentYear == selectedYear
                  ? "this year"
                  : `in ${selectedYear}`}
              </Text>
            </Flex>
          )}
        </Flex>
        <Box pr={"50px"}>
          {loading ? (
            <Center h={"400px"}>
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#3e98d4", "#3cb0fd", "#0973b9", "#3e98d4", "#0099ff"]}
              />
            </Center>
          ) : (
            <LineChart monthlyData={monthlyData} />
          )}
        </Box>
      </Box>

      <Flex
        flexWrap={"wrap"}
        rowGap={"15px"}
        justifyContent={"space-between"}
        mb={"30px"}
        mt={"20px"}
        columnGap={"23px"}
      >
        {boxes?.map((box, index) => {
          return (
            <Box
              flex={1}
              key={"statistics_" + index}
              bg={"white"}
              p={"20px"}
              px={"40px"}
              rounded={"md"}
              boxShadow={"xs"}
            >
              <Box mb={"10px"}>{box?.icon}</Box>
              {index != 2 ? (
                <Text color={"#788698"} fontSize={17}>
                  {box?.label}
                </Text>
              ) : (
                <Flex mt={"17px"} alignItems={"center"} columnGap={"10px"}>
                  <Text
                    color={"#000"}
                    fontSize={22}
                    lineHeight="15px"
                    fontWeight={"bold"}
                  >
                    3.5
                  </Text>
                  <Text lineHeight="12px" color={"#788698"} fontSize={17}>
                    {box?.label}
                  </Text>
                </Flex>
              )}
              {loadingStatistics ? (
                <Box pt={"5px"}>
                  <Spinner size={"md"} color={"#3e98d4"} />
                </Box>
              ) : (
                <Box>
                  {index == 2 ? (
                    <Flex columnGap={"10px"} alignItems={"center"}>
                      <ReactStars
                        count={5}
                        onChange={() => {}}
                        size={30}
                        isHalf={true}
                        color={"#BCC5D1"}
                        edit={false}
                        value={3.5}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                      />
                    </Flex>
                  ) : (
                    <Box>
                      {index == 3 ? (
                        <Flex>
                          <Text
                            color={"#000"}
                            fontSize={22}
                            fontWeight={"bold"}
                          >
                            {statistics[box.key]?.toLocaleString()}
                          </Text>
                          <button
                            onClick={() => {
                              setIsOpen(true);
                            }}
                            className="view-btn"
                          >
                            <IoEye size={20} />
                            View
                          </button>
                        </Flex>
                      ) : (
                        <Text color={"#000"} fontSize={22} fontWeight={"bold"}>
                          {statistics[box.key]?.toLocaleString()}
                        </Text>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          );
        })}
      </Flex>

      <Tabs
        index={tabIndex}
        onChange={onChangeTab}
        variant="soft-rounded"
        colorScheme="primary"
      >
        <TabList>
          <Tab>Accepted Trips</Tab>
          <Tab>Rejected Trips</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {tabIndex == 0 && <DriverAcceptedTrips driverId={params?.id} />}
          </TabPanel>
          <TabPanel>
            {tabIndex == 1 && <DriverRejectedTrips driverId={params?.id} />}
          </TabPanel>
        </TabPanels>
      </Tabs>

      {isOpen && (
        <ReviewsModal
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </Box>
  );
}
